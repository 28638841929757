/**
 * Created by osirvent on 21/03/2017.
 */
angular
    .module('annexaApp')
    .factory('TableFilterFactory', ['$q', 'RestService', 'Language', 'HelperService', function ($q, RestService, Language, HelperService) {
        var factory = {};

        factory.loadData = function (filter) {
            var promises = [];

            _.forEach(filter, function (value) {
                if(_.contains(['select', 'select_linked', 'select-multiple', 'select-tree'], value.type)) {
                    var deferred = $q.defer();

                    switch (value.dataType) {
                        case 'DATABASE':
                            if (typeof value.data === 'string') {
                                RestService.findAll(value.data)
                                    .then(function (data) {
                                        deferred.resolve({id: value.id, data: JSOG.decode(data.data)});
                                    }).catch(function (error) {
                                    deferred.reject(error);
                                });
                            } else {
                                deferred.resolve({id: value.id, data: value.data});
                            }
                            break;
                        case 'QUERY':
                            if (typeof value.data === 'string') {
                                RestService.query(value.data, value.dataQuery, value.dataQueryParams)
                                    .then(function (data) {
                                        deferred.resolve({id: value.id, data: JSOG.decode(data.data)});
                                    }).catch(function (error) {
                                    deferred.reject(error);
                                });
                            } else {
                                deferred.resolve({id: value.id, data: value.data});
                            }
                            break;
                        case 'LOCAL':
                            deferred.resolve({ id: value.id, data: value.data });
                            break;
                        default:
                            deferred.reject('DataType not valid');
                            break;
                    }

                    promises.push(deferred.promise);
                }
            });

            return $q.all(promises);
        }

        var getModel = function(item, selectId) {
            switch (item.type) {
                case 'checkbox':
                    return item.model != undefined ? item.model : (item.preFilter ? item.preFilter : false);
                case 'text':
					if(!item.model && item.preFilter){
						item.model = item.preFilter;
					}
                    return item.model ? item.model : (item.preFilter ? item.preFilter : undefined);
                case 'select-multiple':
                    return item.model ? item.model : (item.preFilter ? item.preFilter : undefined);
                case 'select':
                case 'select_linked':
                    if(!selectId) {
                        return item.model && item.model.id ? {id: item.model.id} : ((item.preFilter || item.preFilter === false) ? {id: item.preFilter} : undefined);
                    } else {
                        return item.model && item.model[selectId] ? {id: item.model[selectId]} : ((item.preFilter || item.preFilter === false) ? {id: item.preFilter} : undefined);
                    }
                case 'select-tree':
                    return item.model && item.model.$selected && item.model.$selected.id ? { id: item.model.$selected.id } : (item.preFilter ? {id: item.preFilter} : undefined);
                case 'select-multiple':
                    return item.model ? item.model : (item.preFilter ? item.preFilter : undefined);
                case 'dateRange':
                    var value = { From: undefined, To: undefined };

                    if(item.model) {
                        if(item.model.dataFrom) {
                            var dateAux = item.model.dataFrom;
                            if(dateAux instanceof Date) {
                            	value.From = new Date(Date.UTC(dateAux.getFullYear(),dateAux.getMonth(),dateAux.getDate(),00,00,00));
                            } else {
                                dateAux = dateAux.split('/');
    							if(dateAux && dateAux.length == 3){
                                	newDate = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],00,00,00));
    								if(newDate != "Invalid Date") {
    									value.From = newDate;
    								} else {
                                		dateAux = HelperService.dateMaskToDate(dateAux);
                                		value.From = new Date(Date.UTC(dateAux[2],dateAux[1],dateAux[0],00,00,00));
    								}
    							}
                            }
                        }

                        if(item.model.dataTo) {
                            var dateAux = item.model.dataTo;
                            if(dateAux instanceof Date) {
                            	value.To = new Date(Date.UTC(dateAux.getFullYear(),dateAux.getMonth(),dateAux.getDate(),23,59,59));
                            } else {
    							dateAux = dateAux.split('/');
                                if(dateAux && dateAux.length == 3){
                                	newDate = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],23,59,59));
                                	if(newDate != "Invalid Date") {
                                		value.To = newDate;
                                	} else {
                                		dateAux = HelperService.dateMaskToDate(dateAux);
                                		value.To = new Date(Date.UTC(dateAux[2],dateAux[1],dateAux[0],23,59,59));
                                	}
    							}
                            }
                        }
                    }else if(item.preFilter){
						if(item.preFilter.dataFrom) {
							if(!item.model){
								item.model = {};
							}
                            var dateAux = item.preFilter.dataFrom;
							dateAux = dateAux.split('/');
							if(dateAux && dateAux.length == 3){
                            	value.From = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],00,00,00));
								if(value.From != "Invalid Date") {
									item.model.dataFrom = value.From;
								} else {
                            		dateAux = HelperService.dateMaskToDate(dateAux);
                            		item.model.dataFrom = new Date(Date.UTC(dateAux[2],dateAux[1],dateAux[0],00,00,00));
								}
							}
                        }

                        if(item.preFilter.dataTo) {
                            if(!item.model){
								item.model = {};
							}
                            var dateAux = item.preFilter.dataTo;
							dateAux = dateAux.split('/');
                            if(dateAux && dateAux.length == 3){
                            	value.To = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],23,59,59));
                            	if(value.To != "Invalid Date") {
                            		item.model.dataTo = value.To;
                            	} else {
                            		dateAux = HelperService.dateMaskToDate(dateAux);
                            		item.model.dataTo = new Date(Date.UTC(dateAux[2],dateAux[1],dateAux[0],23,59,59));
                            	}
							}
                        }
					}

                    return value;
            }

            return undefined;
        }

        factory.getFilterCall = function (filter) {
            var filterCall = {};

            var filterCallColumns = $linq(filter).where("x => !x.callAux && !x.callMeta").toArray();

            _.forEach(filterCallColumns, function (value) {
                if(value.type != 'dateRange') {
                    filterCall[value.id] = getModel(value);
                    if(value.type == 'text'){
                        value.preFilter = undefined;
                    }
                } else {
                    var model = getModel(value);
                    filterCall[value.id + 'From'] = model.From;
                    filterCall[value.id + 'To'] = model.To;
                    value.preFilter = undefined;
                }
            });

            return filterCall;
        }

        factory.getFilterCallAux = function (filter, skipCustomfields) {
            var filterCallAux = {};

            var filterCallAuxColumns = $linq(filter).where("x => x.callAux").toArray();

            _.forEach(filterCallAuxColumns, function (value) {
                if(value.type != 'dateRange') {
                    filterCallAux[value.id] = getModel(value);
                    if(value.type == 'text'){
                        value.preFilter = undefined;
                    }
                } else {
                    var model = getModel(value);
                    filterCallAux[value.id + 'From'] = model.From;
                    filterCallAux[value.id + 'To'] = model.To;
                    value.preFilter = undefined;
                }
				if(value.negated){
					filterCallAux[value.id+'_negated'] = value.negated;
				}
            });
            if(!skipCustomfields) {
                filterCallAux.customFields = factory.getFilterMeta(filter);
            }
			var filterCallColumns = $linq(filter).where("x => !x.callAux && !x.callMeta").toArray();
			_.forEach(filterCallColumns, function (value) {
				if(value.negated){
					filterCallAux[value.id+'_negated'] = value.negated;
				}
            });
            
            return filterCallAux;
        }

        factory.getFilterMeta = function (filter) {
            var filterMeta = {};

            var filterMetaColumns = $linq(filter).where("x => x.callMeta").toArray();

            _.forEach(filterMetaColumns, function (value) {
                filterMeta[value.id] = getModel(value, 'value');
                if(value.type == 'text'){
                    value.preFilter = undefined;
                }
				if(value.negated){
					filterMeta[value.id+'_negated'] = value.negated;
				}
            });

            return filterMeta;
        }

		factory.getFilter = function (filter) {
            var filterCall = {};

            var filterCallColumns = angular.copy(filter);

            _.forEach(filterCallColumns, function (value) {
                if(value.type != 'dateRange') {
                    filterCall[value.id] = getModel(value);
                } else {
                    if(value && value.model){
                    	filterCall[value.id + 'From'] = value.model.dataFrom;
                        filterCall[value.id + 'To'] = value.model.dataTo;
                    } else {
                    	filterCall[value.id + 'From'] = undefined;
                        filterCall[value.id + 'To'] = undefined;
                    }
                }
				if(value.negated){
					filterCall[value.id+'_negated'] = value.negated;
				}
            });

            return filterCall;
        }

        factory.getCustomFieldFilter = function(customField, order) {
            var filterField = {
                id: customField.templateTag,
                order: order,
                label: customField[Language.getActiveColumn()],
                callMeta: true
            };

            if (_.contains(['INPUT', 'TEXTAREA', 'JSON'], customField.frontendType)) {
                if (customField.backendType == 'DATETIME') {
                    filterField.type = 'dateRange';
                } else {
                    filterField.type = 'text';
                }
            } else if (_.contains(['SELECT', 'MULTIPLESELECT', 'CHECKBOX', 'RADIO'], customField.frontendType)) {
                filterField.type = 'select';
                filterField.dataType = 'LOCAL';
                filterField.data = customField.listValues;
                filterField.addAll = true;
                filterField.nameProperty = Language.getActiveColumn();
                filterField.customFieldId = customField.id;
            } else if (customField.frontendType == 'SELECT_LINKED') {
                filterField.type = 'select_linked';
                filterField.dataType = 'LOCAL';
                filterField.data = [];
                filterField.addAll = true;
                filterField.nameProperty = Language.getActiveColumn();
                filterField.linkedData = customField.listValues;
                filterField.linkedCustomFieldId = customField.linkedCustomField.id;
            } else {
                filterField.type = 'UNKNOWN'
            }

            return filterField;
        }

        factory.clearFilterModel = function(field) {
            switch(field.type) {
                case 'select-multiple':
                    return [];
                case 'select':
                case 'select_linked':
                    if(field.data && field.data.length > 0) {
                        return field.data[0];
                    } else {
                        return '';
                    }
                case 'checkbox':
                    return false;
                case 'text':
                    return '';
                case 'dateRange':
                    return undefined;
                case 'select-tree':
                    return {};
            }
        }

        factory.clearFilters = function (filter) {
            _.forEach(filter, function(value, index) {
                switch(value.type) {
                    case 'select-multiple':
                        filter[index].model = [];
                        break;
                    case 'select':
                    case 'select_linked':
                        if(filter[index].data && filter[index].data.length > 0) {
                            filter[index].model = filter[index].data[0];
                        } else {
                            filter[index].model = '';
                        }
                        break;
                    case 'checkbox':
                        filter[index].model = false;
                        break;
                    case 'text':
                        filter[index].model = '';
                        break;
                    case 'dateRange':
                        filter[index].model = undefined;
                        break;
                    case 'select-tree':
                        filter[index].model = {};
                        break;
                }
				filter[index].negated = false;
            });
        }

        return factory;
    }])
    .component('tableFilter', {
        templateUrl: function($element, $attrs) {
            return $attrs.advancedSearch == 'true' ?
                './components/common/table-filter/advanced-table-filter.html' :
                './components/common/table-filter/table-filter.html'
        },
        controller: ['TableFilterFactory', 'HelperService', 'CommonService', 'Language', '$filter', '$rootScope', '$scope', 'globalModals', 'AnnexaFormlyFactory', 'GlobalDataFactory', 'RestService', 'DialogsFactory','CacheFactory', '$state', function (TableFilterFactory, HelperService, CommonService, Language, $filter, $rootScope, $scope, globalModals, AnnexaFormlyFactory, GlobalDataFactory, RestService, DialogsFactory,CacheFactory,$state) {
            var vm = this;

            //region Inici

            //region Advanced Search

            vm.userChoosenFilters = [];
            vm.selectedUserChoosenFilter = undefined;

            //endregion

            vm.collapsed = vm.see != undefined && (vm.see == true || vm.see == 'visible' || vm.see == 'true') ? false : true;

            vm.dataLoaded = false;

            _.forEach(vm.filter, function (value, index) {
                switch (value.type) {
                    case 'select':
                    case 'select_linked':
                        break;
                    case 'select-tree':
                        vm.filter[index].model = vm.filter[index].model ? vm.filter[index].model : {};
                        break;
                    case 'select-multiple':
                        vm.filter[index].model = vm.filter[index].model ? vm.filter[index].model : [];
                        break;
                    case 'checkbox':
                        vm.filter[index].model = vm.filter[index].model != undefined ? vm.filter[index].model : (vm.filter[index].preFilter ? vm.filter[index].preFilter : false);
                    default:
                        vm.filter[index].model = vm.filter[index].model ? vm.filter[index].model : '';
                        break;
                }
				if(value.negated){
					vm.filter[index].negated = vm.filter[index].negated;
				}
                if(value.type == 'dataRange') {
                    vm.filter[index].openFrom = false;
                    vm.filter[index].openTo = false;
                }
            });
			vm.showNegatedFilters = false;
			if($state.current.name == 'annexa.tasks.assignedTasks' || $state.current.name == 'annexa.tasks.manageTasks' || $state.current.name == 'annexa.tasks.createdTasks' || $state.current.name == 'annexa.doc.documents.all' || $state.current.name == 'annexa.reg.input.entries' || $state.current.name == 'annexa.reg.output.entries' || $state.current.name == 'annexa.tram.pending' || $state.current.name == 'annexa.tram.view' || $state.current.name == 'annexa.tram.responsable' || $state.current.name == 'annexa.tram.dossierRightAccess'){
				vm.showNegatedFilters = true;
			}
            TableFilterFactory.loadData(vm.filter)
                .then(function(data) {
                    vm.dataLoaded = true;

                    _.forEach(data, function(value) {
                        var indexFilter = $linq(vm.filter).indexOf("x => x.id == '" + value.id + "'");
                        if(indexFilter != -1) {
                            var data = [];

                            if(vm.filter[indexFilter].type != 'select-tree') {
                                if (vm.filter[indexFilter].addAll) {
                                	var literalAll = $filter('translate')('global.literals.all');
                                    var haveAll = $linq(value.data).where("x =>x."+vm.filter[indexFilter].nameProperty+" == '"+ literalAll +"'").toArray();
                                    if(haveAll && haveAll.length > 0){
                                        data = value.data;
                                    }else {
                                        data = HelperService.addAllSelect(value.data, vm.filter[indexFilter].nameProperty);
                                    }
                                } else {
                                    data = value.data;
                                }
                                if (vm.filter[indexFilter].addItems && vm.filter[indexFilter].addItems.length > 0){
                                    _.forEach(vm.filter[indexFilter].addItems, function(itemToAdd){
                                        if(itemToAdd && itemToAdd.id) {
                                            var existData = $linq(data).where("x => x.id ==" + itemToAdd.id).toArray();
                                            if (!existData || existData.length == 0) {
                                                data.push(itemToAdd);
                                            }
                                        }
                                    });
                                }
                            } else {
                                if(value.data && value.data[0] && !value.data[0].id){
                                    data = value.data;
                                }else{
                                    data = CommonService.getTreeData(value.data, Language.getActiveColumn());
                                }
                            }

                            vm.filter[indexFilter].data = data;

                            if((vm.filter[indexFilter].type == 'select' || vm.filter[indexFilter].type == 'select_linked') && data.length != 0) {
                                var indexSelected = 0;
								if(vm.filter[indexFilter].preFilter === false || vm.filter[indexFilter].preFilter === true){
									var indexPrefilter = $linq(vm.filter[indexFilter].data).indexOf(function(x){
										if(x && x.id != undefined && x.id !== '' && x.id === vm.filter[indexFilter].preFilter){
											return true;
										}else{
											return false;
										}
									});
                                    vm.filter[indexFilter].preFilter = undefined;
                                    indexSelected = indexPrefilter != -1 ? indexPrefilter : indexSelected;
								}else if(vm.filter[indexFilter].preFilter) {
									
                                    var indexPrefilter = $linq(vm.filter[indexFilter].data).indexOf("x => x.id == '" + vm.filter[indexFilter].preFilter + "'");
                                    vm.filter[indexFilter].preFilter = undefined;

                                    indexSelected = indexPrefilter != -1 ? indexPrefilter : indexSelected;
                                }

                                if(!vm.filter[indexFilter].model) {
                                    vm.filter[indexFilter].model = vm.filter[indexFilter].data[indexSelected];
                                }
                            }else  if(vm.filter[indexFilter].type == 'select-multiple' && data.length != 0){
                                if(vm.filter[indexFilter].preFilter && vm.filter[indexFilter].preFilter.length > 0) {
                                    if(!vm.filter[indexFilter].model) {
                                        vm.filter[indexFilter].model = [];
                                    }
                                    _.forEach(vm.filter[indexFilter].preFilter, function(prefilterItem){
                                        var indexPrefilter = $linq(vm.filter[indexFilter].data).indexOf("x => x.id == '" + prefilterItem.id + "'");
                                        indexSelected = indexPrefilter != -1 ? indexPrefilter : indexSelected;
                                        vm.filter[indexFilter].model.push(vm.filter[indexFilter].data[indexSelected]);
                                    });
                                    vm.filter[indexFilter].preFilter = undefined;
                                }
                            }else  if(vm.filter[indexFilter].type == 'select-tree' && data != 0){
								if(vm.filter[indexFilter].preFilter) {
                                    var prefilterValue = undefined;
									_.forEach(data, function(dataAux){
										if(dataAux && dataAux.length > 0 && !prefilterValue){
											prefilterValue = $linq(dataAux).firstOrDefault(undefined, "x => x.id == "+vm.filter[indexFilter].preFilter);
										}
									});
                                    vm.filter[indexFilter].preFilter = undefined;
									if(prefilterValue && !vm.filter[indexFilter].model || !vm.filter[indexFilter].model.$selected) {
	                                    vm.filter[indexFilter].model = {$selected:prefilterValue};
	                                }
                                }
                            }
                        }
                    });

                    var b = 0;
                }).catch(function(error) {
                    vm.dataLoaded = true;
            });

           //endregion

            //region Mètodes

            //region Advanced Search

            vm.getAdvancedFilterNormal = function() {
                return $linq(vm.filter).where("x => !x.userChoosen").orderBy("x => x.order").toArray();
            }

            vm.getAdvancedFilterUserChoosen = function() {
                return $linq($linq(vm.filter).where("x => x.userChoosen").orderBy("x => x.order").toArray()).except(vm.userChoosenFilters, "(x, y) => x.id == y.id").toArray();
            }

            vm.userChoosenFilterSelected = function($item, $model) {
                vm.userChoosenFilters.push($item);
            }

            vm.addUserChoosenFilter = function() {
                vm.selectedUserChoosenFilter = undefined;
            }

            vm.removeUserChoosenFilter = function(index) {
                var indexFilter = $linq(vm.filter).indexOf("x => x.id == '" + vm.userChoosenFilters[index].id + "'");

                if(indexFilter != -1) {
                    vm.filter[indexFilter].model = TableFilterFactory.clearFilterModel(vm.filter[indexFilter])
                }

                vm.userChoosenFilters.splice(index, 1);
                if(vm.userChoosenFilters.length == 0) {
                    vm.selectedUserChoosenFilter = undefined;
                } else {
                    vm.selectedUserChoosenFilter = vm.userChoosenFilters[vm.userChoosenFilters.length - 1];
                }

                vm.submit();
            }

            vm.clearTree = function(field) {
                field.model = {};
                vm.filterProcedure(field);
            }

            //endregion

            vm.showLegend = function() {
                var result = false;

                _.forEach(vm.filter, function (value) {
                    if(value.model) {
                        switch (value.type) {
                            case 'select-multiple':
                                result = result || value.model.length != 0;
                                break
                            case 'select':
                            case 'select_linked':
                                result = result || value.model.id;
                                break;
                            case 'select-tree':
                                if(value.model.hasOwnProperty('$selected')) {
                                    result = result || value.model.$selected.title;
                                }
                                break;
                            case 'text':
                                result = result || value.model;
                                break;
                            case 'checkbox':
                                if(value.model != undefined) {
                                    result = result || value.model;
                                }
                                break;
                            case 'dateRange':
                                result = result || value.model.dataFrom || value.model.dataTo;
                                break;
                        }
                    }
                });

                return result;
            }

			vm.adminFilters = function(){
				var language = Language.getActiveColumn();
        		var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
				var form = new AnnexaFormly();
        		var modal = angular.copy(globalModals.adminFilterModal);
				var hideExpression =  function($viewValue, $modelValue, scope) {
					if(scope && scope.model && scope.model.filterModify){
						return false;
					}
					return true;
				}
				var getModel = function(id, model, itemSelected, filterColumn){
					var a = 0;
					if(id && model && itemSelected && itemSelected.filter && filterColumn ){
						try{
							var modelAux = JSON.parse(itemSelected.filter);
							if(modelAux[id]){
								if(id.endsWith('_negated')){
									return ((modelAux[id])?true:false);
								}else if(filterColumn.type == 'text'){
									return modelAux[id];
								} else if(filterColumn.type == 'checkbox'){
									return ((modelAux[id] && (modelAux[id] === 'true' || modelAux[id] === true))?true:false);
								} else if(filterColumn.type == 'dateRange'){
									var dateProp = undefined;
									if(modelAux[id]){
										try{
											var date = undefined;
											var dateAux = modelAux[id].split('/');
											if(dateAux && dateAux.length == 3){
												var date = new Date(Date.UTC(dateAux[2],dateAux[1]-1,dateAux[0],00,00,00));
												if(date != "Invalid Date") {
				                            		date = $filter('date')(date, 'dd/MM/yyyy');
				                            	} else {
				                            		date = modelAux[id];
				                            	}
											}
			                            	
											return date;
										}catch(e){
											return undefined;
										}
									}else{
										return undefined;
									}
								} else if(filterColumn.type == 'select'){
									if(filterColumn.data && modelAux[id]){
										return $linq(filterColumn.data).firstOrDefault(undefined,"x => x.id+'' == '"+modelAux[id].id+"'");
									}else{
										return undefined;
									}
								} else if(filterColumn.type == 'select-multiple'){
									if(filterColumn.data && modelAux[id]){
										return $linq(filterColumn.data).intersect(modelAux[id], "(x,y) => x.id == y.id").toArray();
									}else{
										return undefined;
									}
								} else if(filterColumn.type == 'select-tree'){
									if(filterColumn.data && modelAux[id]){
										var modelTree = undefined;
										_.forEach(Object.getOwnPropertyNames(filterColumn.data), function(dataAux){
											if(!modelTree && filterColumn.data[dataAux]){
												modelTree = $linq(filterColumn.data[dataAux]).firstOrDefault(undefined, "x => x.id+'' == '"+modelAux[id].id+"'");
											}
										});
										if(modelTree){
											return {$selected: modelTree};
										}else{
											return undefined;
										}
									}else{
										return undefined;
									}
								} else {
									return undefined;
								}
							}else{
								return undefined;
							}
						}catch(e){
							return undefined;
						}
					}else{
						return undefined;
					}
				}
				
                modal.annexaFormly.model = {};
				modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var fieldComboFilter = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'filter'");
				if(fieldComboFilter && fieldComboFilter.templateOptions){
					fieldComboFilter.templateOptions.tableName = vm.tableDefinition.objectType+((vm.tableDefinition.objectType == 'Document' && vm.tableDefinition.objectTypePrefilter)?vm.tableDefinition.objectTypePrefilter:'');
				}
				var fieldComboFilterDelete = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'filterDelete'");
				if(fieldComboFilterDelete && fieldComboFilterDelete.templateOptions){
					fieldComboFilterDelete.templateOptions.tableName = vm.tableDefinition.objectType+((vm.tableDefinition.objectType == 'Document' && vm.tableDefinition.objectTypePrefilter)?vm.tableDefinition.objectTypePrefilter:'');
				}
				var fields = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'filtersRow'");
				if(fields && fields.fieldGroup && vm.filter){
					var fieldComboFilterModify = $linq(fields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'filterModify'");
					if(fieldComboFilterModify && fieldComboFilterModify.templateOptions){
						fieldComboFilterModify.templateOptions.tableName = vm.tableDefinition.objectType+((vm.tableDefinition.objectType == 'Document' && vm.tableDefinition.objectTypePrefilter)?vm.tableDefinition.objectTypePrefilter:'');
					}
					_.forEach(vm.filter, function(filt){
						if(filt.type === 'text'){
							var field = form.createField(filt.id,'annexaInputRow','col-sm-5',new AnnexaFormlyFieldTemplateOptions('text',((filt.label)?filt.label:filt[language]),false,false,undefined,255),data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id] = getModel(filt.id, $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
							var field = form.createField(filt.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'_negated'] = getModel(filt.id+'_negated', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
						}else if(filt.type === 'checkbox'){
							var field = form.createField(filt.id,'annexaCheckbox','col-sm-6 p-t',{type: 'checkbox', label: ((filt.label)?filt.label:filt[language]), required:false},data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id] = getModel(filt.id, $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
						}else if(filt.type === 'dateRange'){
							var onChangeDataPickerMask = function(key) {
								modal.annexaFormly.model.modal_body.filtersRow[key] = $filter('date')(modal.annexaFormly.model.modal_body.filtersRow[key + "Aux"], 'dd/MM/yyyy');
                        	}
							var field = form.createField(filt.id+'From','annexaDatepickerMaskRow','col-sm-3',new AnnexaFormlyFieldDatepickerTemplateOptions('text',((filt.label)?filt.label:filt[language]),false,{format: 'dd/MM/yyyy',initDate:undefined,showWeeks: false,startingDay: 1}, filt.id+'FromAux', onChangeDataPickerMask),data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'From'] = getModel(filt.id+'From', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
							field = form.createField(filt.id+'To','annexaDatepickerMaskRow','col-sm-2',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.literals.dateTo',false,{format: 'dd/MM/yyyy',initDate:undefined,showWeeks: false,startingDay: 1}, filt.id+'ToAux', onChangeDataPickerMask),data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'To'] = getModel(filt.id+'To', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
							var field = form.createField(filt.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'_negated'] = getModel(filt.id+'_negated', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
						}else if(filt.type === 'select'){
							var list = [];
							if(filt.data){
								list = angular.copy(filt.data);
							}
							var field = form.createField(filt.id, 'annexaSelectRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(((filt.label)?filt.label:filt[language]),'id',((filt.nameProperty)?filt.nameProperty:language),list,false),data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id] = getModel(filt.id, $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
							var field = form.createField(filt.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'_negated'] = getModel(filt.id+'_negated', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);	
						}else if(filt.type === 'select-multiple'){
							var list = [];
							if(filt.data){
								list = angular.copy(filt.data);
							}
							var field = form.createField(filt.id, 'annexaMultipleSelectRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(((filt.label)?filt.label:filt[language]), 'id',((filt.nameProperty)?filt.nameProperty:language), list, false),data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id] = getModel(filt.id, $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);	
							var field = form.createField(filt.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'_negated'] = getModel(filt.id+'_negated', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);		
						}else if(filt.type === 'select-tree'){
							var field = form.createField(filt.id, 'annexaSelectTreeRow', 'col-sm-5', new AnnexaFormlyFieldSelectTemplateOptions(((filt.label)?filt.label:filt[language]),'id','title',((filt.data)?filt.data:{}),false, undefined, undefined, undefined, true),data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id] = getModel(filt.id, $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);	
							var field = form.createField(filt.id+'_negated','annexaCheckbox','col-sm-1',{type: 'checkbox', label: 'global.literals.no', required:false},data);
							field.hideExpression = hideExpression;
							field.controller = ['$scope', 'Language', '$rootScope', function($scope, Language, $rootScope) {
                                $scope.$on('newTableFilterModifySelected', function(event, args) {
	                            	if(args.item) {
										$scope.model[filt.id+'_negated'] = getModel(filt.id+'_negated', $scope.model, args.item, filt);
		                            }
                                });
                            }];
							fields.fieldGroup.push(field);
						}
					});
				}
				var updateFilters = function(){
					var self = this;
					if(self && self.annexaFormly && self.annexaFormly.model && self.annexaFormly.model.modal_body && vm.filter && vm.tableDefinition && vm.tableDefinition.objectType){
						var model = self.annexaFormly.model.modal_body;
						if(model.action){
							if(model.action == 'execute'){
								if(model.filter && model.filter.filter){
									var filterParsed = {};
									try{
										filterParsed = JSON.parse(model.filter.filter);	
										_.forEach(vm.filter, function(prop){
											prop.model = undefined;
											prop.negated = false;
										});
										_.forEach(Object.getOwnPropertyNames(filterParsed), function(prop){
											var filterAux = $linq(vm.filter).firstOrDefault(undefined, "x => x.id == '"+prop+"'");
											if(!filterAux && (prop.endsWith('From') || prop.endsWith('To'))){
												var propDate = ((prop.endsWith('From'))?prop.substr(0,prop.length-4):prop.substr(0,prop.length-2));
												filterAux = $linq(vm.filter).firstOrDefault(undefined, "x => x.id == '"+propDate+"'");
											}
											if(filterAux){
												if(filterAux.type === 'text' || filterAux.type === 'checkbox'){
													filterAux.model = filterParsed[prop];
												}else if(filterAux.type === 'dateRange'){
													if(!filterAux.model){
														filterAux.model = {};
													}
													var dateProp = undefined;
													if(filterParsed[prop]){
														try{
															var splitDateAux = filterParsed[prop].split('/');
															if(splitDateAux && splitDateAux.length == 3){
																var fromDate = undefined;
																if(prop.endsWith('From')){
																	fromDate = new Date(Date.UTC(splitDateAux[2],splitDateAux[1]-1,splitDateAux[0],00,00,00));
																} else {
																	fromDate = new Date(Date.UTC(splitDateAux[2],splitDateAux[1]-1,splitDateAux[0],21,59,59));
																}
								                            	if(fromDate != "Invalid Date") {
								                            		dateProp = fromDate;
								                            	} else {
								                            		fromDate = HelperService.dateMaskToDate(splitDateAux);
								                            		if(prop.endsWith('From')){
								                            			dateProp = new Date(Date.UTC(fromDate[2],fromDate[1]-1,fromDate[0],00,00,00));
								                            		} else {
								                            			dateProp = new Date(Date.UTC(fromDate[2],fromDate[1]-1,fromDate[0],21,59,59));
								                            		}
								                            	}
															}else{
																dateProp = new Date(filterParsed[prop]);
															}
														}catch(e){}
													}
													if(prop.endsWith('From')){
														filterAux.model.dataFrom = dateProp;
													}else{
														filterAux.model.dataTo = dateProp;
													}
												}else if(filterAux.type === 'select'){
													if(filterAux.data && filterParsed[prop] && filterParsed[prop].id){
														filterAux.model = $linq(filterAux.data).firstOrDefault(undefined,"x => x.id+'' == '"+filterParsed[prop].id+"'");
													}else{
														filterAux.model = undefined;
													}
												}else if(filterAux.type === 'select-multiple'){
													if(filterAux.data && filterParsed[prop]){
														filterAux.model = $linq(filterAux.data).intersect(filterParsed[prop], "(x,y) => x.id == y.id").toArray();
													}else{
														filterAux.model = undefined;
													}
												}else if(filterAux.type === 'select-tree'){
													if(filterAux.data && filterParsed[prop] && filterParsed[prop].id){
														var modelTree = undefined;
														_.forEach(Object.getOwnPropertyNames(filterAux.data), function(dataAux){
															if(!modelTree && filterAux.data[dataAux]){
																modelTree = $linq(filterAux.data[dataAux]).firstOrDefault(undefined, "x => x.id+'' == '"+filterParsed[prop].id+"'");
															}
														});
														if(modelTree){
															filterAux.model = {$selected: modelTree};
														}else{
															filterAux.model = undefined;
														}
													}else{
														filterAux.model = undefined;
													}
												}
											}else{
												if(prop.endsWith('_negated')){
													var propNegated = prop.substr(0,prop.length-8);
													filterAux = $linq(vm.filter).firstOrDefault(undefined, "x => x.id == '"+propNegated+"'");
													filterAux.negated = ((filterParsed[prop])?true:false);
												}
											}
										});
										vm.submit();
									}catch(e){
										filterParsed = {};
									}
									self.close();
								}else{
									self.alerts.push({ msg: $filter('translate')('global.globalFilters.noFilter') });
								}
							} else if(model.action == 'create') {
								if(model.name && model.type){
									var createOk = false;
									var filterAux = TableFilterFactory.getFilter(vm.filter);
									var tableFilterCreate = {
										name:model.name,
										tableName:vm.tableDefinition.objectType+((vm.tableDefinition.objectType == 'Document' && vm.tableDefinition.objectTypePrefilter)?vm.tableDefinition.objectTypePrefilter:''),
										filter:((filterAux)?JSON.stringify(filterAux):"")
									};
									if(model.type == 'user'){
										if($rootScope.LoggedUser && $rootScope.LoggedUser.id){
											tableFilterCreate.user = {id:$rootScope.LoggedUser.id};
											tableFilterCreate.allUsers = false;
											createOk = true;
										}
									}else if(model.type == 'profile'){
										if(model.profiles && model.profiles.length > 0){
											tableFilterCreate.tableFilterProfiles = [];
											_.forEach(model.profiles, function(prof){
												if(prof && prof.id){
													tableFilterCreate.tableFilterProfiles.push({profile:{id:prof.id}});
												}else{
													tableFilterCreate.tableFilterProfiles.push({profile:{id:prof}});
												}
											});
											tableFilterCreate.allUsers = false;
											createOk = true;
										}
									}else if(model.type == 'global'){
										tableFilterCreate.allUsers = true;
										createOk = true;
									}
									if(createOk){
										tableFilterCreate.state = $state.current.name;
										RestService.insert('./api/table_filter', tableFilterCreate).then(function(data) {
											if(GlobalDataFactory.userFilters){
						                    	GlobalDataFactory.userFilters.length = 0;
						                    }else{
						                    	GlobalDataFactory.userFilters = [];
						                    }
						                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('userFilters')){
						                    	CacheFactory.get('globalDataCache').remove('userFilters');
						                    }
						                    GlobalDataFactory.loadGlobalData();
						                    DialogsFactory.notify($filter('translate')('global.globalFilters.saveOk'));
						                    self.close();
				                        }).catch(function(error){
				                        	self.alerts.push({ msg: $filter('translate')('global.errors.unknownNew')});
				                        });
									}else{
										self.alerts.push({ msg: $filter('translate')('global.globalFilters.noValidType') });
									}
								}else{
									self.alerts.push({ msg: $filter('translate')('global.globalFilters.noType') });
								}
							} else if(model.action == 'modify') {
								if(model.filtersRow.nameModify && model.filtersRow.typeModify && model.filtersRow.filterModify){
									var modifyOk = false;
									var filterAux = angular.copy(vm.filter);
									_.forEach(filterAux, function(faux){
										if(faux.type === 'text' || faux.type === 'checkbox'){
											faux.model = model.filtersRow[faux.id];
											if(faux.type === 'text'){
												faux.negated = ((model.filtersRow[faux.id+'_negated'])?true:false);
											}
										}else if(faux.type === 'dateRange'){
											if(!faux.model){
												faux.model = {};
											}
											var datePropFrom = undefined;
											var datePropTo = undefined;
											if(model.filtersRow[faux.id+'From']){
												try{
													datePropFrom = model.filtersRow[faux.id+'From'];
												}catch(e){}
											}
											if(model.filtersRow[faux.id+'To']){
												try{
													datePropTo = model.filtersRow[faux.id+'To'];
												}catch(e){}
											}
											if(datePropFrom){
												faux.model.dataFrom = datePropFrom;
											}
											if(datePropTo){
												faux.model.dataTo = datePropTo;
											}
											faux.negated = ((model.filtersRow[faux.id+'_negated'])?true:false);
										}else if(faux.type === 'select'){
											if(faux.data && model.filtersRow[faux.id]){
												if(model.filtersRow[faux.id].id){
													faux.model = $linq(faux.data).firstOrDefault(undefined,"x => x.id+'' == '"+model.filtersRow[faux.id].id+"'");
												}else{
													faux.model = $linq(faux.data).firstOrDefault(undefined,"x => x.id+'' == '"+model.filtersRow[faux.id]+"'");	
												}
											}else{
												faux.model = undefined;
											}
											faux.negated = ((model.filtersRow[faux.id+'_negated'])?true:false);
										}else if(faux.type === 'select-multiple'){
											if(faux.data && model.filtersRow[faux.id]){
												faux.model = $linq(faux.data).intersect(model.filtersRow[faux.id], "(x,y) => x == y.id").toArray();
											}else{
												faux.model = undefined;
											}
											faux.negated = ((model.filtersRow[faux.id+'_negated'])?true:false);
										}else if(faux.type === 'select-tree'){
											if(faux.data && model.filtersRow[faux.id] && model.filtersRow[faux.id]){
												var modelTree = undefined;
												_.forEach(Object.getOwnPropertyNames(faux.data), function(dataAux){
													if(!modelTree && faux.data[dataAux]  && model.filtersRow[faux.id].$selected ){
														if(model.filtersRow[faux.id].$selected.id){
															modelTree = $linq(faux.data[dataAux]).firstOrDefault(undefined, "x => x.id+'' == '"+model.filtersRow[faux.id].$selected.id+"'");
														}else{
															modelTree = $linq(faux.data[dataAux]).firstOrDefault(undefined, "x => x.id+'' == '"+model.filtersRow[faux.id].$selected+"'");
														}
													} 
												});
												if(modelTree){
													faux.model = {$selected: modelTree};
												}else{
													faux.model = undefined;
												}
											}else{
												faux.model = undefined;
											}
											faux.negated = ((model.filtersRow[faux.id+'_negated'])?true:false);
										}else{
											faux.model = undefined;
											faux.negated = false;
										}
									});
									filterAux = TableFilterFactory.getFilter(filterAux);
									var tableFilterModify = angular.copy(model.filtersRow.filterModify);
									tableFilterModify.name = model.filtersRow.nameModify;
									tableFilterModify.tableName = vm.tableDefinition.objectType+((vm.tableDefinition.objectType == 'Document' && vm.tableDefinition.objectTypePrefilter)?vm.tableDefinition.objectTypePrefilter:'');
									tableFilterModify.filter = ((filterAux)?JSON.stringify(filterAux):"");
									if(model.filtersRow.typeModify == 'user'){
										if($rootScope.LoggedUser && $rootScope.LoggedUser.id){
											tableFilterModify.user = {id:$rootScope.LoggedUser.id};
											tableFilterModify.tableFilterProfiles = undefined;
											tableFilterModify.allUsers = false;
											modifyOk = true;
										}
									}else if(model.filtersRow.typeModify == 'profile'){
										if(model.filtersRow.profilesModify && model.filtersRow.profilesModify.length > 0){
											tableFilterModify.tableFilterProfiles = [];
											_.forEach(model.filtersRow.profilesModify, function(prof){
												if(prof && prof.id){
													tableFilterModify.tableFilterProfiles.push({profile:{id:prof.id}});
												}else{
													tableFilterModify.tableFilterProfiles.push({profile:{id:prof}});
												}
											});
											tableFilterModify.user = undefined; 
											tableFilterModify.allUsers = false;
											modifyOk = true;
										}
									}else if(model.filtersRow.typeModify == 'global'){
										tableFilterModify.user = undefined; 
										tableFilterModify.tableFilterProfiles = undefined;
										tableFilterModify.allUsers = true;
										modifyOk = true;
									}
									if(modifyOk){
										tableFilterModify.state = $state.current.name;
										RestService.update('./api/table_filter/'+tableFilterModify.id, tableFilterModify).then(function(data) {
											if(GlobalDataFactory.userFilters){
						                    	GlobalDataFactory.userFilters.length = 0;
						                    }else{
						                    	GlobalDataFactory.userFilters = [];
						                    }
						                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('userFilters')){
						                    	CacheFactory.get('globalDataCache').remove('userFilters');
						                    }
						                    GlobalDataFactory.loadGlobalData();
											DialogsFactory.notify($filter('translate')('global.globalFilters.saveOk'));
						                    self.close();
				                        }).catch(function(error){
				                        	self.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate')});
				                        });
									}else{
										self.alerts.push({ msg: $filter('translate')('global.globalFilters.noValidType') });
									}
								}else{
									self.alerts.push({ msg: $filter('translate')('global.globalFilters.noType') });
								}
							} else if(model.action == 'delete') {
								if(model.filterDelete && model.filterDelete.id){
									DialogsFactory.confirm('global.globalFilters.deleteFilter', 'global.globalFilters.deleteFilterConfirm').then(function (data) {
										RestService.checkDelete('./api/table_filter/check_delete/' + model.filterDelete.id).then(function(data) {
											if(data) {
												RestService.delete('./api/table_filter/' + model.filterDelete.id).then(function(data) {
													if(GlobalDataFactory.userFilters){
														GlobalDataFactory.userFilters.length = 0;
													}else{
														GlobalDataFactory.userFilters = [];
													}
													if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('userFilters')){
														CacheFactory.get('globalDataCache').remove('userFilters');
													}
													GlobalDataFactory.loadGlobalData();
													DialogsFactory.notify($filter('translate')('global.globalFilters.deleteOk'));
													self.close();
												}).catch(function(error){
													self.alerts.push({ msg: $filter('translate')('global.errors.unknownDelete')});
												});
											}
										}).catch(function(error) {
											self.alerts.push({ msg: $filter('translate')('global.globalFilters.noPermission')});
										});
									}).catch(function (data) {
										//Empty
									});
								} else {
									self.alerts.push({ msg: $filter('translate')('global.globalFilters.noType') });
								}
							} else {
								self.alerts.push({ msg: $filter('translate')('global.globalFilters.noValidAction') });
							}
						}else{
							self.alerts.push({ msg: $filter('translate')('global.globalFilters.noAction') });
						}
					}else{
						self.alerts.push({ msg: $filter('translate')('global.globalFilters.noObjectType') });
					}
				}
				AnnexaFormlyFactory.showModal("modalUpdatePredefinedFilterModal", modal, updateFilters, false);
			}
			
			var dataFactoryProcedures = angular.copy(GlobalDataFactory.procedures);
			dataFactoryProcedures.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
			
			vm.filterProcedure = function(field) {
				if(vm.filter) {
					var filterToEdit = $linq(vm.filter).firstOrDefault(undefined, "x => x.id == 'procedure'");
				}
				if(field && field.model && field.model.$selected) {
            		var procedures = $linq(GlobalDataFactory.procedures).where("x => x.family.id ==" + field.model.$selected.id).toArray();
            		if(procedures.length > 0) {
            			if(filterToEdit){
            				procedures.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
                			filterToEdit.data = procedures;
                			filterToEdit.model = filterToEdit.data[0];
                		}
            		}
            	}else{
            		if(filterToEdit){
            			filterToEdit.data = dataFactoryProcedures;
            			filterToEdit.model = filterToEdit.data[0];
            		}
            	}
			}
			
			var dataFactoryProcedureProposalTypes = angular.copy(GlobalDataFactory.procedureProposalType);
			dataFactoryProcedureProposalTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
			
			vm.filterProcedureProposalType = function(field) {
				if(vm.filter) {
					var filterToEdit = $linq(vm.filter).firstOrDefault(undefined, "x => x.id == 'procedureProposalType'");
				}
				if(field && field.model && field.model.id != "") {
            		var procedureProposalTypes = $linq(GlobalDataFactory.procedureProposalType).where("x => x.procedure.id ==" + field.model.id).toArray();
        			if(filterToEdit){
        				procedureProposalTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
            			filterToEdit.data = procedureProposalTypes;
            			filterToEdit.model = filterToEdit.data[0];
            		}
            	}else{
            		if(filterToEdit){
            			filterToEdit.data = dataFactoryProcedureProposalTypes;
            			filterToEdit.model = filterToEdit.data[0];
            		}
            	}
			}

            vm.changeValue = function(field, deleted, index) {
                if(field && field.type && deleted){
                    if(field.type == 'select-multiple'){
                        field.model.splice(index, 1);
                    }else if(field.type == 'select'){
                        field.model=field.data[0];
                    }else if(field.type == 'select-tree'){
                        field.model={};
                    }else if(field.type == 'checkbox'){
                        field.model='';
                    }else if(field.type == 'text'){
                        field.model='';
                    }else if(field.type == 'dateRange'){
                        field.model=undefined;
                    }
                }
                
                if(vm && vm.tableDefinition && vm.tableDefinition.origin == "tram" && vm.tableDefinition.id != "tableProcedure" && field && field.id == "family") {
                	vm.filterProcedure(field);
                }
                if(vm && vm.tableDefinition && vm.tableDefinition.origin == "sec" && vm.tableDefinition.id == "tableProposals" && field && field.id == "procedure") {
                	vm.filterProcedureProposalType(field);
                }
                
                if(field.type != 'text') {
                    if(field.type == 'select') {
                        if(field.customFieldId) {
                            var linkedFields = $linq(vm.filter).where("x => x.linkedCustomFieldId == " + field.customFieldId).toArray();

                            _.forEach(linkedFields, function (value) {
                                var firstElement = value.data[0];
                                value.model = firstElement;
                                value.data = [];
                                value.data.push(firstElement);

                                if(field.model.id) {
                                    value.data.push.apply(value.data, $linq(value.linkedData).where("x => x.parentValue.id == " + field.model.id).toArray());
                                }
                            });
                        }
                    }
                    if(deleted){
						field.negated = false;
                        $rootScope.$broadcast('tableDataRemoveFilterComplete');
                    }else{
                        vm.submit();
                    }
                } else if(field.type == 'text' && (field.model == '' || field.model.length >= 3)) {
                    if(deleted){
                        field.negated = false;
						$rootScope.$broadcast('tableDataRemoveFilterComplete');
                    }else{
                        vm.submit();
                    }
                }
            }

            vm.clearFilters = function() {
                TableFilterFactory.clearFilters(vm.filter);
                vm.filterProcedure(undefined);
                vm.filterProcedureProposalType(undefined);
                vm.submit();
            }

            //endregion

            $scope.$on('annexaUISelectTreeCleared', function (event, args) {
                if(args.idModel) {
                    var index = $linq(vm.filter).indexOf("x => x.id == '" + args.idModel + "'");

                    if(index != -1) {
                        vm.changeValue(vm.filter[index], true);
                    }
                }
            })
        }],
        bindings: {
            filter: '=',
            submit: '&',
            see: '=?',
            advancedSearch: '=',
			tableDefinition: '='
        }
    })