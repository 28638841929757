angular
    .module('annexaApp')
    .component('annexaCustomFieldComponent', {
        templateUrl: './components/common/annexa-custom-field-component/annexa-custom-field-component.html',
        controller: ['$http', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaEntityFactory', 'Language', '$filter', 'HelperService', 'CommonService', '$scope', function($http, $rootScope, globalModals, AnnexaFormlyFactory, AnnexaEntityFactory, Language, $filter, HelperService, CommonService, $scope) {
            var vm = this;
            vm.groupData = {};
            //region Datepicker

            vm.datepicker = {};

            vm.datepicker.opened = false;

            vm.datepicker.open = function ($event) {
                vm.datepicker.opened = !vm.datepicker.opened;
            };

            vm.datepickerOptions = {
                format: 'dd/MM/yyyy',
                initDate: new Date(),
                showWeeks: false,
                startingDay: 1
            }
            
			vm.onChangeDataPickerMask = function(key, type) {
            	vm.actualModel = $filter('date')(vm.actualModelAux, 'dd/MM/yyyy');
            	vm.optionOnSelectAux(key, type);
        	}
            
            //endregion

            //region Number

            vm.step = 0;
            vm.actualModel = '';
            vm.actualModelAux = '';

            if(vm.frontendType == 'INPUT') {
                vm.inputTextType = 'text';

                if(!vm.minLength) {
                    vm.minLength = 0;
                }

                if(!vm.maxLength) {
                    vm.maxLength = 9999;
                }

                if(!vm.decimalPositions) {
                    vm.decimalPositions = 0;
                }

                if (vm.backendType == 'INTEGER') {
                    vm.inputTextType = 'number';
                    vm.step = 1;
                } else if (vm.backendType == 'DECIMAL') {
                    vm.inputTextType = 'number';
                    vm.step = '0.' + Array(vm.decimalPositions).join('0') + '1';
                }
            }

            if(vm.frontendType == 'TEXTAREA' || vm.frontendType == 'JSON') {
                if(!vm.minLength) {
                    vm.minLength = 0;
                }

                if(!vm.maxLength) { 
                    vm.maxLength = 9999;
                }

            }

            //endregion

            //region Select

            vm.clear = function($event,model$select) {
                $event.stopPropagation();
                model = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            }

            //endregion
            
            //region Address
            
            vm.openModalAddress = function(model, key) {
                var modal = angular.copy(globalModals.editAddressCF);
                
                var defaultCountry = $rootScope.app.configuration.default_country;
                if(defaultCountry && defaultCountry.value){
                    defaultCountry = defaultCountry.value;
                }else{
                    defaultCountry = '';
                }
                
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                
                if(model != "") {
                	modal.annexaFormly.model.modal_body = JSON.parse(model);
                }
                
                if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                    modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                }
                
                modal.annexaFormly.model.modal_body.key = key;
                modal.annexaFormly.model.modal_body.hasStates = true;
                modal.annexaFormly.model.modal_body.hasCities = true;
                modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                
                modal.extra = this;
                
                
                var submitFunction = function() { 
                    var self = this;
                    
                    var address = {};
                    var addressAux = this.annexaFormly.model.modal_body;

                    address.country = addressAux.country;
                    if(addressAux.hasStates) {
                        if (addressAux.validState && addressAux.validState.id) {
                            address.validState = addressAux.validState;
                        }
                    }else{
                        address.stateAddress = addressAux.stateAddress;
                    }
                    if(addressAux.hasCities){
                        if(addressAux.validCity && addressAux.validCity.id) {
                            address.validCity = addressAux.validCity;
                        }
                    }else{
                        address.city = addressAux.city;
                    }
                    if(addressAux.hasStreetTypes && addressAux.streetType) {
                        address.streetType = addressAux.streetType;
                    }

                    address.street = addressAux.street;
                    address.isValidNumber = false;
                    address.numberAddres = addressAux.numberAddres;
                    address.floor = addressAux.floor;
                    address.block = addressAux.block;
                    address.door = addressAux.door;
                    address.stair = addressAux.stair;
                    address.postalCode = addressAux.postalCode;
                    
                    vm.optionOnSelect(addressAux.key, JSON.stringify(address));
                    if(vm.customField && vm.customField.relatedCustomField && vm.customField.relatedCustomField.id){
                    	vm.model = JSON.stringify(address);
                    }
                    vm.modelValue = AnnexaEntityFactory.postalAddressRender(address)
                    
                    self.close();
                }
                
                AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
            };
            
            //endregion

            this.$onInit = function () {
            	vm.groupData.extra = vm.extra;
            	if(!vm.isRequired){
            		vm.isRequired = false;
            	}
            	if(!vm.isConfiguration){
            		vm.isConfiguration = false;
            	}
                if(!vm.showFunctionParam){
                    vm.showFunction = function(){
                        return true;
                    }
                }else{
                    vm.showFunction = vm.showFunctionParam;
                }
                if(typeof vm.options === 'string') {
                    var url = vm.options;
                    vm.options = [];

                    $http({
                        url: url,
                        method: 'GET'
                    }).then(function(data) {
                        var decodedData = JSOG.decode(data.data);
                        vm.options = angular.fromJson(decodedData);
                    }).catch(function (error) {
                        vm.options = [];
                    });
                }
                if(vm.backendType  == 'DATETIME' && vm.model){
                    if(vm.isConfiguration) {
                    	vm.actualModel = vm.model;
                    } else {
                    	if(vm.model instanceof Date){
                    		vm.actualModel = vm.model;
                        }else{
                        	var dateParts = vm.model.split("/");
	                        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	    					if(dateObject != "Invalid Date") {
	    						vm.actualModel = dateObject;
	    					} else {
	    						dateParts = HelperService.dateMaskToDate(dateParts);
	    						vm.actualModel = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	    					}
                        }
                    }
                }else  if (vm.frontendType == 'INPUT' && (vm.backendType == 'INTEGER')) {
                    vm.actualModel = parseInt(vm.model);
                } else if (vm.frontendType == 'INPUT' && vm.backendType == 'DECIMAL') {
                    if(vm.model){
                    	if(typeof vm.model === 'string' || vm.model instanceof String){
                    		var valueLiteral_parts = (vm.model).split('.');
                    		if(valueLiteral_parts && valueLiteral_parts.length > 0){
                    			if(valueLiteral_parts[1]){
                    				if(vm.decimalPositions){
                    					vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(vm.decimalPositions));
                    				}else{
                    					vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                    				}
                    			}else{
                    				if(vm.decimalPositions){
                    					vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(vm.decimalPositions));
                    				}else {
                    					vm.actualModel =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                    				}
                    			}
                    		}
                    	}else{
                    		vm.actualModel =  vm.model;
                    	}
                    }
                }  else if(vm.frontendType == 'INPUT' && vm.backendType == 'ADDRESS') {
                	if(vm.model && vm.model != ""){
                		vm.modelValue = AnnexaEntityFactory.postalAddressRender(JSON.parse(vm.model));
                	}
                } else if(vm.frontendType == 'RADIO' || vm.frontendType == 'SELECT'  || (vm.frontendType == 'SELECT_LINKED' && vm.extra && vm.extra.seeSelectedLinked === true)){
                    if(vm.backendType == 'INTEGER' && vm.model){
                    	vm.actualModel = parseInt(vm.model);
                    }else{
                    	vm.actualModel = vm.model;
                    }
                }else if(vm.frontendType == 'CHECKBOX'){
                    vm.actualModel = [];
                    if(vm.options && vm.optionValueProp){
                        var modelsSelected = [];
                        if(vm.model){
                            modelsSelected = vm.model.split(',');
                        }
                        _.forEach(vm.options, function(option){
                            if(_.contains(modelsSelected, ""+option[vm.optionValueProp])){
                                vm.actualModel[option[vm.optionValueProp]] = true;
                            }else{
                                vm.actualModel[option[vm.optionValueProp]] = false;
                            }
                        });
                    }
                }else if(vm.frontendType == 'MULTIPLESELECT'){
                    vm.actualModel = [];
                    if(vm.options && vm.optionValueProp){
                        var modelsSelected = [];
                        if(vm.model){
                            modelsSelected = vm.model.split(',');
                        }
                        _.forEach(modelsSelected, function(option){
                            if(vm.backendType == 'INTEGER' && option){
                            	vm.actualModel.push(parseInt(option));
                            }else{
                            	vm.actualModel.push(option);
                            } 
                        });
                    }
                }else if(vm.frontendType == 'STRUCTURAL_SELECT'){
                	vm.checkSelectable = false;
                	vm.onlyLastLevelClick = false;
                	if(vm.extra){
                		if(vm.extra.checkSelectable){
                			vm.checkSelectable = vm.extra.checkSelectable;
                		}
                		if(vm.extra.onlyLastLevelClick){
                			vm.onlyLastLevelClick = vm.extra.onlyLastLevelClick
                		}
                	}
                    vm.actualModel = {};
                	vm.optionsTree = [];
                    if(vm.options && vm.optionValueProp && vm.optionLabelProp){
                    	var opts = angular.copy(vm.options);
                    	_.forEach(opts, function(opt){
                    		opt.parent = ((opt.parentValue && opt.parentValue.id)?{id: opt.parentValue.id}:undefined);
                    	});
                    	vm.optionsTree = CommonService.getTreeData(opts, vm.optionLabelProp);
                    	if(vm.model && vm.actualModel && !vm.actualModel.$selected) {
	                    	if(vm.optionsTree){
	                    		var keys = Object.keys(vm.optionsTree);
		                		_.forEach(keys, function(key){
		                			if(vm.optionsTree && vm.optionsTree[key] && vm.optionsTree[key].length > 0){
		                				_.forEach(vm.optionsTree[key], function(opt){
		                					if(vm.backendType == 'INTEGER' && vm.model && opt.id === parseInt(vm.model)){
		                						vm.actualModel.$selected = opt;
		                					}else if(vm.model && opt.id+'' == vm.model){
		                						vm.actualModel.$selected = opt;
		                					}
		                				});
		                			}
		                		});
                    		}
                    	}
                    }
                }
                $scope.$on('customFieldSelectLinkedModifyListValues', function (event, args) {
                    if(args.customField && args.customField.customField && args.customField.customField.id+"" === vm.key && args.customField.customField.frontendType === vm.frontendType) {
                    	if(args.customField.customField.listValues !== undefined){
                    		if(vm.options){
                    			vm.options.length = 0;
                    			_.forEach(args.customField.customField.listValues, function(opt){
                    				vm.options.push(opt);
                    			});
                    		}else{
                    			vm.options = args.customField.customField.listValues;
                    		}
                    	}else{
                    		if(vm.options){
                    			vm.options.length = 0;
                    		}else{
                    			vm.options = [];
                    		}
                    	}
                    	vm.actualModel = undefined;
                    }
                });
                $scope.$on('customFieldSelectValueModifyRelatedValues', function (event, args) {
                    if(args.customField && args.customField.customField && args.customField.customField.id+"" === vm.key && args.customField.customField.frontendType === vm.frontendType) {
                    	if(args.customField.value){
                    		if(args.customField.customField.frontendType == 'STRUCTURAL_SELECT'){
                    			if(vm.actualModel){
                    				vm.actualModel.$selected = {id:args.customField.value};
                    			}else{
                    				vm.actualModel = {$selected:{id:args.customField.value}};
                    			}
                    		}else if(args.customField.customField.frontendType == 'CHECKBOX'){
                                vm.actualModel = [];
                                if(vm.options && vm.optionValueProp){
                                    var modelsSelected = [];
                                    var isString = false;
                                    if(args.customField.value){
                                    	if(args.customField.value instanceof String){
                                    		isString = true;
                                    		modelsSelected = args.customField.value.split(',');
                                    	}else if(Array.isArray(args.customField.value)){
                                    		modelsSelected = args.customField.value;
                                    	}else{
                                    		modelsSelected = [args.customField.value];
                                    	}
                                    }
                                    _.forEach(vm.options, function(option){
                                    	if(isString && _.contains(modelsSelected, ""+option[vm.optionValueProp])){
                                            vm.actualModel[option[vm.optionValueProp]] = true;
                                        }else if(!isString && _.contains(modelsSelected, option[vm.optionValueProp])){
                                            vm.actualModel[option[vm.optionValueProp]] = true;
                                        }else{
                                            vm.actualModel[option[vm.optionValueProp]] = false;
                                        }
                                    });
                                }
                            }else if(args.customField.customField.frontendType == 'MULTIPLESELECT'){
                                vm.actualModel = [];
                                if(vm.options && vm.optionValueProp){
                                    var modelsSelected = [];
                                    if(args.customField.value){
                                    	if(args.customField.value instanceof String){
                                    		modelsSelected = args.customField.value.split(',');
                                    	}else if(Array.isArray(vm.model)){
                                    		modelsSelected = args.customField.value;
                                    	}else{
                                    		modelsSelected = [args.customField.value];
                                    	}
                                    }
                                    _.forEach(modelsSelected, function(option){
                                        if(vm.backendType == 'INTEGER' && option){
                                        	vm.actualModel.push(parseInt(option));
                                        }else{
                                        	vm.actualModel.push(option);
                                        } 
                                    });
                                }
                            }else{
                        		vm.actualModel = args.customField.value;
                    		}
                    	}else{
                    		if(args.customField.customField.frontendType == 'STRUCTURAL_SELECT'){
                    			vm.actualModel = {};
                    		}else{
                    			vm.actualModel = undefined;
                    		}
                    	}
                    }
                });
            }

            this.$onChanges = function (changes) {
                if(changes.options) {
                    if(typeof vm.options === 'string') {
                        var url = vm.options;
                        vm.options = [];

                        $http({
                            url: url,
                            method: 'GET'
                        }).then(function(data) {
                            var decodedData = JSOG.decode(data.data);
                            decodedData = $linq(decodedData).orderBy("x => x." + Language.getActiveColumn()).toArray();
                            vm.options = angular.fromJson(decodedData);
                            if(vm.options && vm.options.length > 0){
                            	var defaultOption = $linq(vm.options).firstOrDefault(undefined, "x=>x.defaultValue == true")
                            	if(defaultOption){
                            		vm.actualModel = defaultOption.id;
                            	}
                            }
                        }).catch(function (error) {
                            vm.options = [];
                        });
                    }
                }
            }

            var previousModel;

            this.$doCheck = function() {
                if(!angular.equals(previousModel, this.model)) {
                    previousModel = this.model;

                    $rootScope.$broadcast('annexaCustomFieldComponentModelChanged', { key: this.key, model: this.model, plugin: this.plugin });
                }
            };

            vm.optionOnSelectAux = function(key, type){
                if(type){
                    if(type == 'DATETIME'){
                        if(vm.actualModel) {
                            vm.model = vm.actualModel;
                        }else{
                            vm.model = '';
                        }
                        if(vm.optionOnSelect){
                        	if(vm.customField && vm.customField.relatedCustomField && vm.customField.relatedCustomField.id){
                                vm.optionOnSelect(key, vm.model, vm.customField);
                        	}else{
                        		vm.optionOnSelect(key, vm.model);
                        	}
                        }

                    }else if(type == 'number' || type == 'RADIO' || type == 'SELECT'){
                        if(vm.actualModel){
                            vm.model = vm.actualModel;
                        }else{
                            vm.model = '';
                        }
                        if(vm.optionOnSelect){
                        	if(vm.customField && vm.customField.relatedCustomField && vm.customField.relatedCustomField.id){
                                vm.optionOnSelect(key, vm.model, vm.customField);
                        	}else{
                        		vm.optionOnSelect(key, vm.model);
                        	}
                        }
                    }else if(type == 'CHECKBOX') {
                        vm.model = '';
                        _.forEach(vm.options, function (option, index) {
                            if (vm.actualModel[option[vm.optionValueProp]]) {
                                if (!vm.model) {
                                    vm.model += option[vm.optionValueProp];
                                } else {
                                    vm.model += "," + option[vm.optionValueProp];
                                }
                            }
                        });
                        if(vm.optionOnSelect){
                        	if(vm.customField && vm.customField.relatedCustomField && vm.customField.relatedCustomField.id){
                                vm.optionOnSelect(key, vm.model, vm.customField);
                        	}else{
                        		vm.optionOnSelect(key, vm.model);
                        	}
                        }
                    } else if(type == 'MULTIPLESELECT'){
                        vm.model = '';
                        if(vm.actualModel) {
                            _.forEach(vm.actualModel, function (option, index) {
                                if (!vm.model) {
                                    vm.model += option;
                                } else {
                                    vm.model += "," + option;
                                }
                            });
                        }
                        if(vm.optionOnSelect){
                        	if(vm.customField && vm.customField.relatedCustomField && vm.customField.relatedCustomField.id){
                                vm.optionOnSelect(key, vm.model, vm.customField);
                        	}else{
                        		vm.optionOnSelect(key, vm.model);
                        	}
                        }
                    } else if(type == 'STRUCTURAL_SELECT'){
                        vm.model = '';
                        if(vm.actualModel && vm.actualModel.$selected && vm.actualModel.$selected.id) {
                        	vm.model = vm.actualModel.$selected.id;
                        }
                        if(vm.optionOnSelect){
                        	if(vm.customField && vm.customField.relatedCustomField && vm.customField.relatedCustomField.id){
                                vm.optionOnSelect(key, vm.model, vm.customField);
                        	}else{
                        		vm.optionOnSelect(key, vm.model);
                        	}
                        }
                    }
                }
            }
        }],
        bindings: {
            key: '@',
            frontendType: '@',
            backendType: '@',
            label: '@',
            model: '=?',
            minLength: '=',
            maxLength: '=',
            decimalPositions: '=',
            isRequired: '=?', 
            cfDisabled: '=',
            optionLabelProp: '@',
            optionValueProp: '@',
            options: '<',
            optionsInline: '=',
            optionOnSelect: '=',
            fc: '=',
            plugin: '<?',
            showFunctionParam: '=',
            isConfiguration: '=?',
            extra: '=?',
            customField: '=?'
        }
    })
	.directive('validateJson', function() {
		return {
		    require: 'ngModel',
		    restrict: '',
		    link: function(scope, elm, attrs, ctrl) {
		        ctrl.$validators.validateJson = function(modelValue) {
		        	if (modelValue) {
			        	try {
		            		return JSON.parse(modelValue);
		                } catch (error) {
		        			return false;
		        		}
		        	}
		        };
		    }
		};
	});