/**
 * Created by osirvent on 22/03/2017.
 */
angular
    .module('annexaApp')
    .directive('rowSelect', ['$compile', '$rootScope', function ($compile, $rootScope) {
        var getTemplate = function () {
            var template = '';

            template += '<label class="btn bnt-primary">';
            template += '    <input type="checkbox" name="rowSelected" ng-click="$ctrl.selectRow()"/>'
            template += '</label>'

            return template;
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                id: '@'
            },
            link: function (scope, element, attr) {
                scope.selectRow = function(id) {
                    $rootScope.$broadcast('submitForm', { id: 'new-registerentry' });
                }

                element.html(getTemplate()).show();

                $compile(element.contents())(scope);
            }
        }
    }])
    .component('tableData', {
        templateUrl: './components/common/table-data/table-data.html',
        controller: ['DTOptionsBuilder', 'DTColumnBuilder', 'RestService', 'Language', '$compile', '$filter', '$rootScope', '$compile', '$scope', '$timeout', function(DTOptionsBuilder, DTColumnBuilder, RestService, Language, $compile, $filter, $rootScope, $compile, $scope, $timeout) {
            var vm = this;
            var startedSelectedTypeResponse = true;
        	var endedSelectedTypeResponse = false;
            //region Ajust paràmetres definició taula

            if(!vm.tableDefinition.id) {
                vm.tableDefinition.id = 'tabledata';
            }

            if(!vm.tableDefinition.classes) {
                vm.tableDefinition.classes = '';
            }

            if(!vm.tableDefinition.sortName) {
                vm.tableDefinition.sortName = '';
            }
            
            if(vm.tableDefinition.processing == undefined) {
                vm.tableDefinition.processing = true;
            }

            if(vm.tableDefinition.serverSide == undefined) {
                vm.tableDefinition.serverSide = true;
            }

            if(vm.tableDefinition.lengthChange == undefined) {
                vm.tableDefinition.lengthChange = true;
            }

            if(vm.tableDefinition.filter == undefined) {
                vm.tableDefinition.filter = false;
            }
            
            if(vm.tableDefinition.stateSave == undefined) {
                vm.tableDefinition.stateSave = true;
            }

            if(vm.tableDefinition.iDisplayLength == undefined) {
                vm.tableDefinition.iDisplayLength = 10;
            }
            
            if(vm.tableDefinition.lengthMenu == undefined) {
                if(vm.tableDefinition.displayAll && vm.tableDefinition.displayAll == true){
                	vm.tableDefinition.lengthMenu = [[5,10,25,50,100,1000], [5,10,25,50,100,1000]];
                }else{
                	vm.tableDefinition.lengthMenu = [[5,10,25,50,100], [5,10,25,50,100]];
                }
            }
            
            if(vm.tableDefinition.sort == undefined) {
                vm.tableDefinition.sort = [[0, 'asc']];
            }

            if(vm.tableDefinition.responsive == undefined) {
                vm.tableDefinition.responsive = true;
            }

            if(vm.tableDefinition.compileRow == undefined) {
                vm.tableDefinition.compileRow = true;
            }

            if(vm.tableDefinition.compileHeader == undefined) {
                vm.tableDefinition.compileHeader = true;
            }

            if(vm.tableDefinition.showTopButtons == undefined) {
                vm.tableDefinition.showTopButtons = true;
            }

            if(vm.tableDefinition.customDom == undefined) {
                vm.tableDefinition.customDom = false;
            }

            if(vm.tableDefinition.useTableObjects == undefined) {
                vm.tableDefinition.useTableObjects = false;
            }

            if(!vm.tableDefinition.dom) {
                vm.tableDefinition.dom = 'rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>';
            }

            if(vm.tableDefinition.compileRow == undefined) {
                vm.tableDefinition.compileRow = vm.tableDefinition.useTableObjects;
            }

            if(vm.tableDefinition.noLoading == undefined) {
                vm.tableDefinition.noLoading = false;
            }
            
            if(vm.tableDefinition.skipFirstSearch == undefined) {
                vm.tableDefinition.skipFirstSearch = false;
            }
            
            vm.tableDefinition.totalRecords = undefined;

			vm.tableDefinition.actualOrder = undefined;
			
            //endregion

            //region inici

            vm.tableObjects = {};
            vm.table = {};
            vm.headerCompiled = false;
            vm.dtOptions = DTOptionsBuilder.newOptions()
                .withOption('ajax',
                    function (data, callback, settings) {
						settings.maxLengthAnnexaValues = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.data_table_max_length && $rootScope.app.configuration.data_table_max_length.value)?$rootScope.app.configuration.data_table_max_length.value:undefined);
                        var setData = function(total, filtered, objects) {
                            data.draw = settings.iDraw;
                            data.recordsTotal = total;
                            data.recordsFiltered = filtered;
                            data.data = objects;
                            vm.tableDefinition.list = objects;
                            vm.tableDefinition.totalRecords = total;
                            vm.tableDefinition.containerScope.tableObjects = {};
                            if(vm.tableDefinition.parentObject && vm.tableDefinition.parentObjectAttribute){
                           		vm.tableDefinition.containerScope.objectToCall = vm.tableDefinition.parentObject[vm.tableDefinition.parentObjectAttribute];
                            }
                            if(vm.tableDefinition.useTableObjects) {
                                _.forEach(objects, function (object) {
                                    if(object.id) {
                                        vm.tableDefinition.containerScope.tableObjects[object.id] = object;
                                    }
                                })
                            }
                            callback(data);
							$scope.$broadcast('dataTableContentLoaded',{});
                        }
                        
                        var callToServer = function(page, sizePage, sort){
                        	RestService.filterData(vm.tableDefinition.origin, vm.tableDefinition.objectType,vm.tableDefinition.filterCall, vm.tableDefinition.filterCallAux, page, sizePage, sort, 0, vm.tableDefinition.callOrigin, vm.tableDefinition.noLoading)
                            .then(function(dataSearch) {
                            	if(page > 0 && dataSearch && dataSearch.data && (!dataSearch.data.content || dataSearch.data.content.length == 0 )){
                            		if(vm.table && vm.table.DataTable && vm.table.DataTable.page){
                                        vm.table.DataTable.page('previous');
                            		}	
                            		callToServer(((page > 0)?page-1:0), sizePage, sort);
                            	}else{
                                    if(dataSearch.data && dataSearch.data.content) {
	                                    setData(dataSearch.data.totalElements, dataSearch.data.totalElements, dataSearch.data.content);
	                                } else {
	                                    setData(0, 0, []);
	                                }
                            	}
	                        }).catch(function (error) {
	                                setData(0, 0, [])
	                        });
                        }
                        
                        if(vm.tableDefinition.origin && vm.tableDefinition.objectType) {
                            var sizePage = settings._iDisplayLength != -1 ? settings._iDisplayLength : settings._iRecordsTotal;
                            if(sizePage > 250 && (!vm.tableDefinition.displayAll || vm.tableDefinition.displayAll != true)){
                        		sizePage = 10;
                        	}
                            var page = settings._iDisplayStart > 0 ? settings._iDisplayStart / sizePage : settings._iDisplayStart;
                            var sort = "";
                            if(data.order ==  null || data.order == undefined || data.order.length == 0 || (!data.columns[data.order[0].column].orderable)) {
                                if(vm.tableDefinition && vm.tableDefinition.sortName) {
                                    if(vm.tableDefinition.sortName.endsWith(',asc') || vm.tableDefinition.sortName.endsWith(',desc')){
                                    	sort = vm.tableDefinition.sortName;
                                    }else{
                                        sort = vm.tableDefinition.sortName + ",desc";
                                    }
                                } 
                            } else {
                                if(data.columns[data.order[0].column].orderable) {
                                    sort = data.columns[data.order[0].column].data + "," + data.order[0].dir;
                                } else if(vm.tableDefinition && vm.tableDefinition.sortName) {
                                	if(vm.tableDefinition.sortName.endsWith(',asc') || vm.tableDefinition.sortName.endsWith(',desc')){
                                		sort = vm.tableDefinition.sortName; 
                                	}else{
                                        sort = vm.tableDefinition.sortName + ",desc";
                                	}
                                } 
                            }
							vm.tableDefinition.actualOrder = sort;
                            if(vm.tableDefinition.skipFirstSearch) {
                            	setData(0, 0, []);
                            	vm.tableDefinition.skipFirstSearch = false;
                            } else {
                                callToServer(page, sizePage, sort);
                            }
                        } else {
                            if(vm.tableDefinition.objects) {
                                setData(vm.tableDefinition.objects.length, vm.tableDefinition.objects.length, vm.tableDefinition.objects);
                            }else if(vm.tableDefinition.objectsPaginated) {
                            	var sizePage = settings._iDisplayLength != -1 ? settings._iDisplayLength : settings._iRecordsTotal;
                            	if(sizePage > 250 && (!vm.tableDefinition.displayAll || vm.tableDefinition.displayAll != true)){
                            		sizePage = 10;
                            	}
                            	var page = settings._iDisplayStart > 0 ? settings._iDisplayStart / sizePage : settings._iDisplayStart;
                                vm.tableDefinition.objectsPaginated(page, sizePage).then(function(dataSearch) {
                                    if(dataSearch && dataSearch.content) {
                                        setData(dataSearch.totalElements, dataSearch.totalElements, dataSearch.content);
                                    } else {
                                        setData(0, 0, []);
                                    }
                                }).catch(function (error) {
                                    setData(0, 0, []);
                                    $rootScope.$broadcast('executeQueryResultErrorComponent',{error: error});
                                });
                            } else {
                                setData(0, 0, []);
                            }
                        }
                    }
                )
                .withDataProp(function(json) {
                    return json.data;
                })
                .withLanguageSource('./i18n/' + Language.getActiveLang() + '/datatable.json')
                .withOption('fnInitComplete', function(oSettings, json) {
                    $rootScope.$broadcast('tableDataInitComplete');
                })
                .withOption('processing', vm.tableDefinition.processing)
                .withOption('serverSide', vm.tableDefinition.serverSide)
                .withOption('bLengthChange', vm.tableDefinition.lengthChange)
                .withOption('bFilter', vm.tableDefinition.filter)
                .withOption('aaSorting', vm.tableDefinition.sort)
                .withOption('lengthMenu', vm.tableDefinition.lengthMenu)
                .withOption('stateSave', vm.tableDefinition.stateSave)
                .withOption('responsive', vm.tableDefinition.responsive)
                .withDisplayLength(vm.tableDefinition.iDisplayLength)

            if(vm.tableDefinition.customDom) {
                vm.dtOptions.withDOM(vm.tableDefinition.dom);
            } else {
                vm.dtOptions.withDOM('<"clearfix p-t-sm p-r-sm"<"text-right"B>>' + vm.tableDefinition.dom);
            }

            // Estos seran los botones tres botones oscuros de [Excel - Columnas - Recargar]
            if(vm.tableDefinition.showTopButtons) {
                var buttons = [];
				if(!vm.tableDefinition || !vm.tableDefinition.notAddExcel){
					buttons.push({extend: 'excelHtml5',className: 'grey'});
				}
                if(!vm.tableDefinition.objectsPaginated) {
                	 buttons.push({
                        extend: 'colvis',
                        className: 'grey buttons-columns',
                        text: $filter('translate')('global.literals.columns'),
                        columnText: function (dt, idx, title) {
                        	if(title && title == 'NotShow'){
                        		title = '';
                        	}else{
	                            switch (dt.column(idx).dataSrc()) {
	                                case 'dossier.favoriteUsers':
	                                    if(dt.settings()[0].sTableId == 'tableDossierTransactionView'){
	                                        title = $filter('translate')('global.literals.favorite');
	                                    }
	                                    break;
	                                case 'createdDate':
	                                    title = $filter('translate')('global.literals.creation_date');
	                                    break;
	                                case 'docStatus':
	                                    title = $filter('translate')('global.literals.state');
	                                    break;
	                                case 'favorite':
	                                    title = $filter('translate')('global.literals.favorite');
	                                    break;
	                                case 'user':
	                                    if (dt.settings()[0].sTableId == 'tableSessionAttendees' && idx == 1) {
	                                        title = $filter('translate')('global.profile.email');
	                                    } else if (dt.settings()[0].sTableId == 'tableSessionAttendees' && idx == 2) {
	                                        title = $filter('translate')('global.profile.phone');
	                                    }
	                                    break;
	                            }
	
	                            if(!title) {
	                                title = '--';
	                            }else if(title.indexOf('global.literals.showOptions') > 0){
	                            	title = $filter('translate')('global.literals.showOptions');
	                            }
                        	}
                            return title;
                        }
                    });
                }
                buttons.push({
                        text: $filter('translate')('global.literals.reload'),
                        className: 'grey buttons-reload',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
							if(vm.tableDefinition.callFunctionOnReload) {
				                vm.tableDefinition.callFunctionOnReload();
				            }
                        }
                    });

                if(vm.tableDefinition.multipleActions) {
                    buttons.push({
                        extend: 'collection',
                        text: $filter('translate')('global.literals.actions'),
                        className: 'grey',
                        buttons: [
                        ],
                        enabled: false
                    });
                }

                vm.dtOptions.withButtons(buttons);
            }

            if(vm.tableDefinition.compileRow) {
                vm.dtOptions.withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)(vm.tableDefinition.containerScope);
                })
            }

            if(vm.tableDefinition.compileHeader) {
                vm.dtOptions.withOption('headerCallback', function (header) {
                    if(!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        $compile(angular.element(header).contents())(vm.tableDefinition.containerScope);
                    }
                })
            }

            if(vm.tableDefinition.multipleActions) {
                vm.tableDefinition.useTableObjects = true;
                vm.tableDefinition.multipleActionsFunction = function (options) {
                    var button =  {
                        extend: 'collection',
                        text: $filter('translate')('global.literals.actions'),
                        className: 'grey',
                        buttons: options ? options : [],
                        enabled: options ? true : false
                    };

                    vm.table.DataTable.button(3).remove();
                    vm.table.DataTable.button().add(3, button);
                }
            }

            vm.tableDefinition.containerScope.isSelectAll = false;
            vm.tableDefinition.containerScope.selected = {};

            vm.tableDefinition.containerScope.showCheck = function(item) {
                if(vm.tableDefinition.functionMultipleActionsColumnShow) {
                    return vm.tableDefinition.functionMultipleActionsColumnShow(item);
                }

                return true;
            }

            vm.tableDefinition.containerScope.selectAll = function() {
                _.forEach(vm.tableDefinition.containerScope.tableObjects, function(item) {
                    item.selected = vm.tableDefinition.containerScope.isSelectAll;
                });

                if(vm.tableDefinition.functionMultipleActionsReload) {
                    vm.tableDefinition.functionMultipleActionsReload(vm.tableDefinition.containerScope.tableObjects);
                }
            }

            vm.tableDefinition.containerScope.selectOne = function() {
                vm.tableDefinition.containerScope.isSelectAll = false;

                if(vm.tableDefinition.functionMultipleActionsReload) {
                    vm.tableDefinition.functionMultipleActionsReload(vm.tableDefinition.containerScope.tableObjects);
                }
            }

            // aqui cargaremos las columnas
            var loadColumns = function(columns) {
                vm.dtColumns = [];

                if(vm.tableDefinition.multipleActions) {
                    var multipleActionsColumnTitle = '';
                    multipleActionsColumnTitle += '<div class="checkbox checkbox-sucess">';
                    multipleActionsColumnTitle += ' <input type="checkbox" ng-click="selectAll()" ng-model="isSelectAll">';
                    multipleActionsColumnTitle += '</div>';
                    var multipleActionsColumnRender = function(data, type, full, meta) {
                        return '<input ng-if="showCheck(tableObjects[' + full.id + '])" type="checkbox" ng-model="tableObjects[' + full.id + '].selected" ng-click="selectOne()">';
                    }

                    vm.dtColumns.push(new DTColumnBuilder.newColumn("id").withTitle(multipleActionsColumnTitle).notSortable().renderWith(multipleActionsColumnRender))
                }


                _.forEach(columns, function(value) {
                    var newColumn = DTColumnBuilder.newColumn(value.hasOwnProperty('columnName') ? value.columnName : value.id);

                    if(value.hasOwnProperty('visible') && value.visible == false) {
                        newColumn = newColumn.notVisible();
                    }

                    if(value.hasOwnProperty('sortable') && value.sortable == false) {
                        newColumn = newColumn.notSortable();
                    }

                    if(value.hasOwnProperty('width')) {
                        newColumn = newColumn.withOption('width', value.width);
                    }

                    if(value.hasOwnProperty('className')) {
                        newColumn = newColumn.withOption('className', value.className);
                    }

                    if(value.hasOwnProperty('title')) {
                        newColumn = newColumn.withTitle(value.title);
                    }

                    if(value.hasOwnProperty('render')) {
                        newColumn = newColumn.renderWith(value.render);
                    }

                    if(value.hasOwnProperty('column') && !value.hasOwnProperty('title')) {
                        newColumn = newColumn.withTitle(value.column.getTitle());
                    }

                    if(value.hasOwnProperty('column') && !value.hasOwnProperty('render')) {
                        newColumn = newColumn.renderWith(value.column.getRender);
                    }

                    if(value.hasOwnProperty('defaultContent')) {
                        newColumn = newColumn.withOption('defaultContent', value.defaultContent);
                    }

                    vm.dtColumns.push(newColumn);
                });
            }

            loadColumns(vm.tableDefinition.columns);

            vm.tableDefinition.reloadInternalData = function(resetPaging, force) {
                var filterCallCopy = angular.copy(vm.tableDefinition.filterCall);
                var filterCallAuxCopy = angular.copy(vm.tableDefinition.filterCallAux);
                vm.tableDefinition.filterCall = vm.tableDefinition.filterCallFunc();
                vm.tableDefinition.filterCallAux = vm.tableDefinition.filterCallAuxFunc();
                var callback = function(json) {
            	     vm.tableDefinition.containerScope.tableObjects = {};

                    if(vm.tableDefinition.useTableObjects) {
                        _.forEach(vm.table.DataTable.data(), function (object) {
                            if(object.id) {
                                vm.tableDefinition.containerScope.tableObjects[object.id] = object;
                            }
                        })
                    }                    
                    $rootScope.$broadcast('AnnexaReloadInternalDataFinished', { table: vm.tableDefinition.id, objectType: vm.tableDefinition.objectType });
                }
                if((!(JSON.stringify(filterCallCopy) === JSON.stringify( vm.tableDefinition.filterCall) && JSON.stringify(filterCallAuxCopy) === JSON.stringify( vm.tableDefinition.filterCallAux)) || force) && vm.table && vm.table.reloadData) {
                    vm.table.reloadData(callback, resetPaging);
                } 
            }
           
            vm.tableDefinition.loadColumns = function (columns) {
                loadColumns(columns);
            }

			$scope.$on( 'dataTableContentLoaded' , function(){
				$timeout(function () {
					angular.element(".dataTable .dropdown-toggle").parent().on("show.bs.dropdown", function(){
						angular.element(this).parents(".dataTables_wrapper").css("padding-bottom", angular.element(this).find(".dropdown-menu").height()+'px');
					});
					angular.element(".dataTable .dropdown-toggle").parent().on("hide.bs.dropdown", function(){
						angular.element(this).parents(".dataTables_wrapper").css("padding-bottom", '0px');
					});
                });
    	    });
            //endregion

        }],
        bindings: {
            tableDefinition: '='
        }
    })
	.component('tableDataIdentifier', {
        templateUrl: './components/common/table-data/table-data.html',
        controller: ['DTOptionsBuilder', 'DTColumnBuilder', 'RestService', 'Language', '$compile', '$filter', '$rootScope', '$compile', '$scope', '$timeout', function(DTOptionsBuilder, DTColumnBuilder, RestService, Language, $compile, $filter, $rootScope, $compile, $scope, $timeout) {
            var vm = this;
            var startedSelectedTypeResponse = true;
        	var endedSelectedTypeResponse = false;
            //region Ajust paràmetres definició taula

            if(!vm.tableDefinition.id) {
                vm.tableDefinition.id = 'tabledata';
            }

            if(!vm.tableDefinition.classes) {
                vm.tableDefinition.classes = '';
            }

            if(!vm.tableDefinition.sortName) {
                vm.tableDefinition.sortName = '';
            }
            
            if(vm.tableDefinition.processing == undefined) {
                vm.tableDefinition.processing = true;
            }

            if(vm.tableDefinition.serverSide == undefined) {
                vm.tableDefinition.serverSide = true;
            }

            if(vm.tableDefinition.lengthChange == undefined) {
                vm.tableDefinition.lengthChange = true;
            }

            if(vm.tableDefinition.filter == undefined) {
                vm.tableDefinition.filter = false;
            }
            
            if(vm.tableDefinition.stateSave == undefined) {
                vm.tableDefinition.stateSave = true;
            }

            if(vm.tableDefinition.iDisplayLength == undefined) {
                vm.tableDefinition.iDisplayLength = 10;
            }
            
            if(vm.tableDefinition.lengthMenu == undefined) {
                if(vm.tableDefinition.displayAll && vm.tableDefinition.displayAll == true){
                	vm.tableDefinition.lengthMenu = [[5,10,25,50,100,1000], [5,10,25,50,100,1000]];
                }else{
                	vm.tableDefinition.lengthMenu = [[5,10,25,50,100], [5,10,25,50,100]];
                }
            }
            
            if(vm.tableDefinition.sort == undefined) {
                vm.tableDefinition.sort = [[0, 'asc']];
            }

            if(vm.tableDefinition.responsive == undefined) {
                vm.tableDefinition.responsive = true;
            }

            if(vm.tableDefinition.compileRow == undefined) {
                vm.tableDefinition.compileRow = true;
            }

            if(vm.tableDefinition.compileHeader == undefined) {
                vm.tableDefinition.compileHeader = true;
            }

            if(vm.tableDefinition.showTopButtons == undefined) {
                vm.tableDefinition.showTopButtons = true;
            }

            if(vm.tableDefinition.customDom == undefined) {
                vm.tableDefinition.customDom = false;
            }

            if(vm.tableDefinition.useTableObjects == undefined) {
                vm.tableDefinition.useTableObjects = false;
            }

            if(!vm.tableDefinition.dom) {
                vm.tableDefinition.dom = 'rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>';
            }

            if(vm.tableDefinition.compileRow == undefined) {
                vm.tableDefinition.compileRow = vm.tableDefinition.useTableObjects;
            }

            if(vm.tableDefinition.noLoading == undefined) {
                vm.tableDefinition.noLoading = false;
            }
            
            if(vm.tableDefinition.skipFirstSearch == undefined) {
                vm.tableDefinition.skipFirstSearch = false;
            }
            
            vm.tableDefinition.totalRecords = undefined;

			vm.tableDefinition.actualOrder = undefined;
			
            //endregion

            //region inici

            vm.tableObjects = {};
            vm.table = {};
            vm.headerCompiled = false;
            vm.dtOptions = DTOptionsBuilder.newOptions()
                .withOption('ajax',
                    function (data, callback, settings) {
						settings.maxLengthAnnexaValues = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.data_table_max_length && $rootScope.app.configuration.data_table_max_length.value)?$rootScope.app.configuration.data_table_max_length.value:undefined);
                        var setData = function(total, filtered, objects) {
                            data.draw = settings.iDraw;
                            data.recordsTotal = total;
                            data.recordsFiltered = filtered;
                            data.data = objects;
                            vm.tableDefinition.list = objects;
                            vm.tableDefinition.totalRecords = total;
                            vm.tableDefinition.containerScope.tableObjects = {};
                            if(vm.tableDefinition.parentObject && vm.tableDefinition.parentObjectAttribute){
                           		vm.tableDefinition.containerScope.objectToCall = vm.tableDefinition.parentObject[vm.tableDefinition.parentObjectAttribute];
                            }
                            if(vm.tableDefinition.useTableObjects) {
                                _.forEach(objects, function (object) {
                                    if(object.id) {
                                        vm.tableDefinition.containerScope.tableObjects[object.id] = object;
                                    }
                                })
                            }
                            callback(data);
							$scope.$broadcast('dataTableContentLoaded',{});
                        }
                        
                        var callToServer = function(page, sizePage, sort){
                        	RestService.filterById(vm.tableDefinition.origin, vm.tableDefinition.objectType,vm.tableDefinition.objectIdToSelectOrigin, vm.tableDefinition.objectIdToSelectType, vm.tableDefinition.objectIdToSelect, page, sizePage, sort, 0, vm.tableDefinition.callOrigin, vm.tableDefinition.noLoading)
                            .then(function(dataSearch) {
                            	if(page > 0 && dataSearch && dataSearch.data && (!dataSearch.data.content || dataSearch.data.content.length == 0 )){
                            		if(vm.table && vm.table.DataTable && vm.table.DataTable.page){
                                        vm.table.DataTable.page('previous');
                            		}	
                            		callToServer(((page > 0)?page-1:0), sizePage, sort);
                            	}else{
                                    if(dataSearch.data && dataSearch.data.content) {
	                                    setData(dataSearch.data.totalElements, dataSearch.data.totalElements, dataSearch.data.content);
	                                } else {
	                                    setData(0, 0, []);
	                                }
                            	}
	                        }).catch(function (error) {
	                                setData(0, 0, [])
	                        });
                        }
                        
                        var sizePage = settings._iDisplayLength != -1 ? settings._iDisplayLength : settings._iRecordsTotal;
                        if(sizePage > 250 && (!vm.tableDefinition.displayAll || vm.tableDefinition.displayAll != true)){
                    		sizePage = 10;
                    	}
                        var page = settings._iDisplayStart > 0 ? settings._iDisplayStart / sizePage : settings._iDisplayStart;
                        var sort = "";
                        if(data.order ==  null || data.order == undefined || data.order.length == 0 || (!data.columns[data.order[0].column].orderable)) {
                            if(vm.tableDefinition && vm.tableDefinition.sortName) {
                                if(vm.tableDefinition.sortName.endsWith(',asc') || vm.tableDefinition.sortName.endsWith(',desc')){
                                	sort = vm.tableDefinition.sortName;
                                }else{
                                    sort = vm.tableDefinition.sortName + ",desc";
                                }
                            } 
                        } else {
                            if(data.columns[data.order[0].column].orderable) {
                                sort = data.columns[data.order[0].column].data + "," + data.order[0].dir;
                            } else if(vm.tableDefinition && vm.tableDefinition.sortName) {
                            	if(vm.tableDefinition.sortName.endsWith(',asc') || vm.tableDefinition.sortName.endsWith(',desc')){
                            		sort = vm.tableDefinition.sortName; 
                            	}else{
                                    sort = vm.tableDefinition.sortName + ",desc";
                            	}
                            } 
                        }
						vm.tableDefinition.actualOrder = sort;
                        if(vm.tableDefinition.skipFirstSearch) {
                        	setData(0, 0, []);
                        	vm.tableDefinition.skipFirstSearch = false;
                        } else {
                            callToServer(page, sizePage, sort);
                        }
                    }
                )
                .withDataProp(function(json) {
                    return json.data;
                })
                .withLanguageSource('./i18n/' + Language.getActiveLang() + '/datatable.json')
                .withOption('fnInitComplete', function(oSettings, json) {
                    $rootScope.$broadcast('tableDataInitComplete');
                })
                .withOption('processing', vm.tableDefinition.processing)
                .withOption('serverSide', vm.tableDefinition.serverSide)
                .withOption('bLengthChange', vm.tableDefinition.lengthChange)
                .withOption('bFilter', vm.tableDefinition.filter)
                .withOption('aaSorting', vm.tableDefinition.sort)
                .withOption('lengthMenu', vm.tableDefinition.lengthMenu)
                .withOption('stateSave', vm.tableDefinition.stateSave)
                .withOption('responsive', vm.tableDefinition.responsive)                
                .withDisplayLength(vm.tableDefinition.iDisplayLength)

            if(vm.tableDefinition.customDom) {
                vm.dtOptions.withDOM(vm.tableDefinition.dom);
            } else {
                vm.dtOptions.withDOM('<"clearfix p-t-sm p-r-sm"<"text-right"B>>' + vm.tableDefinition.dom);
            }

            // Estos seran los botones tres botones oscuros de [Excel - Columnas - Recargar]
            if(vm.tableDefinition.showTopButtons) {
                var buttons = [
                    {
                        extend: 'excelHtml5',
                        className: 'grey'
                    }];
                if(!vm.tableDefinition.objectsPaginated) {
                	 buttons.push({
                        extend: 'colvis',
                        className: 'grey buttons-columns',
                        text: $filter('translate')('global.literals.columns'),
                        columnText: function (dt, idx, title) {
                        	if(title && title == 'NotShow'){
                        		title = '';
                        	}else{
	                            switch (dt.column(idx).dataSrc()) {
	                                case 'dossier.favoriteUsers':
	                                    if(dt.settings()[0].sTableId == 'tableDossierTransactionView'){
	                                        title = $filter('translate')('global.literals.favorite');
	                                    }
	                                    break;
	                                case 'createdDate':
	                                    title = $filter('translate')('global.literals.creation_date');
	                                    break;
	                                case 'docStatus':
	                                    title = $filter('translate')('global.literals.state');
	                                    break;
	                                case 'favorite':
	                                    title = $filter('translate')('global.literals.favorite');
	                                    break;
	                                case 'user':
	                                    if (dt.settings()[0].sTableId == 'tableSessionAttendees' && idx == 1) {
	                                        title = $filter('translate')('global.profile.email');
	                                    } else if (dt.settings()[0].sTableId == 'tableSessionAttendees' && idx == 2) {
	                                        title = $filter('translate')('global.profile.phone');
	                                    }
	                                    break;
	                            }
	
	                            if(!title) {
	                                title = '--';
	                            }else if(title.indexOf('global.literals.showOptions') > 0){
	                            	title = $filter('translate')('global.literals.showOptions');
	                            }
                        	}
                            return title;
                        }
                    });
                }
                buttons.push({
                        text: $filter('translate')('global.literals.reload'),
                        className: 'grey buttons-reload',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
							if(vm.tableDefinition.callFunctionOnReload) {
				                vm.tableDefinition.callFunctionOnReload();
				            }
                        }
                    });

                if(vm.tableDefinition.multipleActions) {
                    buttons.push({
                        extend: 'collection',
                        text: $filter('translate')('global.literals.actions'),
                        className: 'grey',
                        buttons: [
                        ],
                        enabled: false
                    });
                }

                vm.dtOptions.withButtons(buttons);
            }

            if(vm.tableDefinition.compileRow) {
                vm.dtOptions.withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)(vm.tableDefinition.containerScope);
                })
            }

            if(vm.tableDefinition.compileHeader) {
                vm.dtOptions.withOption('headerCallback', function (header) {
                    if(!vm.headerCompiled) {
                        vm.headerCompiled = true;
                        $compile(angular.element(header).contents())(vm.tableDefinition.containerScope);
                    }
                })
            }

            if(vm.tableDefinition.multipleActions) {
                vm.tableDefinition.useTableObjects = true;
                vm.tableDefinition.multipleActionsFunction = function (options) {
                    var button =  {
                        extend: 'collection',
                        text: $filter('translate')('global.literals.actions'),
                        className: 'grey',
                        buttons: options ? options : [],
                        enabled: options ? true : false
                    };

                    vm.table.DataTable.button(3).remove();
                    vm.table.DataTable.button().add(3, button);
                }
            }

            vm.tableDefinition.containerScope.isSelectAll = false;
            vm.tableDefinition.containerScope.selected = {};

            vm.tableDefinition.containerScope.showCheck = function(item) {
                if(vm.tableDefinition.functionMultipleActionsColumnShow) {
                    return vm.tableDefinition.functionMultipleActionsColumnShow(item);
                }

                return true;
            }

            vm.tableDefinition.containerScope.selectAll = function() {
                _.forEach(vm.tableDefinition.containerScope.tableObjects, function(item) {
                    item.selected = vm.tableDefinition.containerScope.isSelectAll;
                });

                if(vm.tableDefinition.functionMultipleActionsReload) {
                    vm.tableDefinition.functionMultipleActionsReload(vm.tableDefinition.containerScope.tableObjects);
                }
            }

            vm.tableDefinition.containerScope.selectOne = function() {
                vm.tableDefinition.containerScope.isSelectAll = false;

                if(vm.tableDefinition.functionMultipleActionsReload) {
                    vm.tableDefinition.functionMultipleActionsReload(vm.tableDefinition.containerScope.tableObjects);
                }
            }

            // aqui cargaremos las columnas
            var loadColumns = function(columns) {
                vm.dtColumns = [];

                if(vm.tableDefinition.multipleActions) {
                    var multipleActionsColumnTitle = '';
                    multipleActionsColumnTitle += '<div class="checkbox checkbox-sucess">';
                    multipleActionsColumnTitle += ' <input type="checkbox" ng-click="selectAll()" ng-model="isSelectAll">';
                    multipleActionsColumnTitle += '</div>';
                    var multipleActionsColumnRender = function(data, type, full, meta) {
                        return '<input ng-if="showCheck(tableObjects[' + full.id + '])" type="checkbox" ng-model="tableObjects[' + full.id + '].selected" ng-click="selectOne()">';
                    }

                    vm.dtColumns.push(new DTColumnBuilder.newColumn("id").withTitle(multipleActionsColumnTitle).notSortable().renderWith(multipleActionsColumnRender))
                }


                _.forEach(columns, function(value) {
                    var newColumn = DTColumnBuilder.newColumn(value.hasOwnProperty('columnName') ? value.columnName : value.id);

                    if(value.hasOwnProperty('visible') && value.visible == false) {
                        newColumn = newColumn.notVisible();
                    }

                    if(value.hasOwnProperty('sortable') && value.sortable == false) {
                        newColumn = newColumn.notSortable();
                    }

                    if(value.hasOwnProperty('width')) {
                        newColumn = newColumn.withOption('width', value.width);
                    }

                    if(value.hasOwnProperty('className')) {
                        newColumn = newColumn.withOption('className', value.className);
                    }

                    if(value.hasOwnProperty('title')) {
                        newColumn = newColumn.withTitle(value.title);
                    }

                    if(value.hasOwnProperty('render')) {
                        newColumn = newColumn.renderWith(value.render);
                    }

                    if(value.hasOwnProperty('column') && !value.hasOwnProperty('title')) {
                        newColumn = newColumn.withTitle(value.column.getTitle());
                    }

                    if(value.hasOwnProperty('column') && !value.hasOwnProperty('render')) {
                        newColumn = newColumn.renderWith(value.column.getRender);
                    }

                    if(value.hasOwnProperty('defaultContent')) {
                        newColumn = newColumn.withOption('defaultContent', value.defaultContent);
                    }

                    vm.dtColumns.push(newColumn);
                });
            }

            loadColumns(vm.tableDefinition.columns);

            vm.tableDefinition.reloadInternalData = function(resetPaging, force) {
                var callback = function(json) {
            	     vm.tableDefinition.containerScope.tableObjects = {};

                    if(vm.tableDefinition.useTableObjects) {
                        _.forEach(vm.table.DataTable.data(), function (object) {
                            if(object.id) {
                                vm.tableDefinition.containerScope.tableObjects[object.id] = object;
                            }
                        })
                    }                    
                    $rootScope.$broadcast('AnnexaReloadInternalDataFinished', { table: vm.tableDefinition.id, objectType: vm.tableDefinition.objectType });
                }
                vm.table.reloadData(callback, resetPaging);
            }
           
            vm.tableDefinition.loadColumns = function (columns) {
                loadColumns(columns);
            }

			$scope.$on( 'dataTableContentLoaded' , function(){
				$timeout(function () {
					angular.element(".dataTable .dropdown-toggle").parent().on("show.bs.dropdown", function(){
						angular.element(this).parents(".dataTables_wrapper").css("padding-bottom", angular.element(this).find(".dropdown-menu").height()+'px');
					});
					angular.element(".dataTable .dropdown-toggle").parent().on("hide.bs.dropdown", function(){
						angular.element(this).parents(".dataTables_wrapper").css("padding-bottom", '0px');
					});
                });
    	    });
            //endregion

        }],
        bindings: {
            tableDefinition: '='
        }
    })